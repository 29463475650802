/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Media,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import { toast, Flip } from 'react-toastify';
import '../../../../assets/scss/pages/users.scss';
import jwtDecode from 'jwt-decode';
import ModalWindowFullScreen from '../../../../components/main/Modals/FullScreen';
import ViewTripModal from '../../Trips/Modals/ViewModal';
import PdfViewer from '../../Accounting/Modals/PdfViewer';
import unitService from '../../../../services/units';
import stateProvinceService from '../../../../services/state_province';
import tripService from '../../../../services/trips';
import accountingService from '../../../../services/accounting';
import { fetchData } from '../../../../redux/modules/driver_trips/actions';
import { useQuery } from '../../useQuery';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { driverTripsHeader } from '../../../../redux/constants/table/headers';

export default function DriverLoads({ driverId, headerMaker }) {
  const {
    query,
    search,
    status,
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    setSearch,
    setStatus,
    setTripStatusId,
    setTruckId,
    setDriverId,
    setStart,
    setEnd,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [checkDate, setCheckDate] = useState(new Date().getTime());

  const [states, setStates] = useState([]);
  const [driverStatuses, setDriverStatuses] = useState([]);
  const [datas, setDatas] = useState({});
  const [trips, setTrips] = useState();
  // const [total, setTotal] = useState();

  // const [loading, setLoading] = useState(true);
  const [statusChanged, setStatusChanged] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const [unitStatuses, setUnitStatuses] = useState([]);
  const [statuss, setStatuss] = useState();
  const [eldUnTil, setEldUnTil] = useState();
  const [modal, setModal] = useState(false);
  const [initialLocation, setInitialLocation] = useState();

  const [showPdfViewerModal, setShowPdfViewerModal] = useState(false);
  const [pdfData, setPdfData] = useState([]);

  const [sendAlert, setSendAlert] = useState(false);
  const [sendData, setSendData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [tripId, setTripId] = useState();

  // const token = localStorage.getItem(
  //   localStorage.getItem('current_account') + '_access_token'
  // );
  // const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const headers = useMemo(
    () => headerMaker(driverTripsHeader),
    [driverTripsHeader]
  );

  const { data, loading, total } = useSelector(
    (state) => state.driverTripsReducer
  );
  localStorage.setItem('total_data', total);

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  const handleModal = () => {
    setModal(!modal);
  };

  const handleSendAlert = () => {
    setSendAlert(!sendAlert);
  };

  const doubleClick = (id, tableName) => {
    setTripId(id);
    setShowModal(true);
  };

  useEffect(() => {
    // componentDidMount();
    setDriverId(driverId);
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <ModalWindowFullScreen
        title="Trip"
        modal={showModal}
        handleModal={() => {
          setShowModal(false);
        }}
        body={
          <ViewTripModal
            modal={showModal}
            id={tripId}
            toggle={() => {
              setShowModal(false);
            }}
          />
        }
      />
      <ModalWindowFullScreen
        title="Document"
        modal={showPdfViewerModal}
        handleModal={() => {
          setShowPdfViewerModal(!showPdfViewerModal);
        }}
        maxWidth=""
        body={
          <PdfViewer
            modal={showPdfViewerModal}
            toggle={() => {
              setShowPdfViewerModal(!showPdfViewerModal);
            }}
            data={pdfData}
            driverId={driverId}
            startDate={startDate}
            endDate={endDate}
            handleSendAlert={handleSendAlert}
          />
        }
      />
      <Card className="overflow-hidden agGrid-card">
        <div className="d-flex justify-content-between m-2">
          <h3>Trips list</h3>
        </div>
        <CardBody className="py-0 no-pagination">
          <Form>
            <Row>
              <Col md="6" sm="12">
                <FormGroup>
                  <Label for="search">Search</Label>
                  <Input
                    autoComplete="off"
                    type="text"
                    id="search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="2" sm="12">
                <FormGroup>
                  <Label for="from">Start</Label>
                  <Flatpickr
                    id="from"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                    value={startDate}
                    onChange={(val) => {
                      setStartDate(new Date(val).getTime());
                      setStart(new Date(val).getTime());
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="2" sm="12">
                <FormGroup>
                  <Label for="end">End</Label>
                  <Flatpickr
                    id="end"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                    value={endDate}
                    onChange={(val) => {
                      console.log(val);
                      setEndDate(new Date(val).getTime());
                      setEnd(new Date(val).getTime());
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md="2" sm="12">
                <FormGroup>
                  <Label for="download">{}</Label>
                  <Button
                    disabled={!startDate || !endDate || !checkDate}
                    size="sm"
                    color="success"
                    className="form-control"
                    type="button"
                    onClick={() => {
                      const query = `driver_id=${driverId}&start=${startDate}&end=${endDate}&check_date=${checkDate}`;
                      accountingService.getStatement2(query).then((res) => {
                        setPdfData([res]);
                      });
                      setShowPdfViewerModal(true);
                    }}
                  >
                    Get invoice
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <div className="w-100 ag-grid-table with-pagination">
            <Table
              data={data}
              headers={headers}
              // toolTips={toolTips}
              onChange={handleOnTableChange}
              modal={handleModal}
              doubleClick={doubleClick}
              columns={columns}
              setColumns={setColumns}
              // hidePagination={1}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}
