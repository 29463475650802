/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';
import * as Icon from 'react-feather';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Flatpickr from 'react-flatpickr';
import { toast, Flip } from 'react-toastify';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ownedCompanyService from '../../../../services/owned_company';
import accountingService from '../../../../services/accounting';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default function PdfViewer({
  modal,
  toggle,
  data,
  driverId,
  startDate,
  endDate,
  handleSendAlert,
}) {
  const [file, setFile] = useState('');
  const [item, setItem] = useState();
  const [itemIndex, setItemIndex] = useState(-1);
  const [addDisable, setAddDisable] = useState(false);
  const [subDisable, setSubDisable] = useState(true);

  const [fontSize, setFontSize] = useState(8);
  const [pdfData, setPdfData] = useState([]);

  const [checkDate, setCheckDate] = useState(new Date().getTime());

  const [ownedCompanies, setOwnedCompanies] = useState([]);
  const [company, setCompany] = useState('0');

  const getFile = (name) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/accounting/files/${name}`, {
      method: 'GET',
      headers: {
        Authorization:
          'Bearer ' +
          localStorage.getItem(
            localStorage.getItem('current_account') + '_access_token'
          ),
      },
    })
      .then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          setFile(fileURL);
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const add = () => {
    if (itemIndex < data.length - 1) {
      setItem(data[itemIndex + 1]);
      setItemIndex(itemIndex + 1);
      getFile(data[itemIndex + 1].fileName);
      setAddDisable(false);
      setSubDisable(false);
    }
    if (itemIndex + 1 === data.length - 1) {
      setAddDisable(true);
    }
  };

  const sub = () => {
    if (itemIndex > 0) {
      setItem(data[itemIndex - 1]);
      setItemIndex(itemIndex - 1);
      getFile(data[itemIndex - 1].fileName);
      setSubDisable(false);
      setAddDisable(false);
    }
    if (itemIndex - 1 === 0) {
      setSubDisable(true);
    }
  };

  const getCompanies = () => {
    ownedCompanyService
      .getAllDashboard('deleted=false&sort=id,DESC&size=10000')
      .then((data) => {
        if (data.length > 0) {
          setCompany(data[0].id);
        }
        setOwnedCompanies(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    getCompanies();
    setAddDisable(false);
    setSubDisable(true);

    if (data.length === 1) {
      setAddDisable(true);
      setSubDisable(true);
    }
    if (data[0]) {
      setItemIndex(0);
      setItem(data[0]);
      getFile(data[0].fileName);
    }
  }, [data]);

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div style={{ paddingLeft: 5 }}>
        <Row>
          <Col md="4" sm="12">
            <FormGroup>
              <Label for="company">Select company</Label>
              <CustomInput
                type="select"
                name="select"
                id="company"
                onChange={(e) => {
                  console.log(e.target.value);
                  setCompany(e.target.value);
                }}
              >
                {ownedCompanies.map((item, i) => (
                  <option key={`${i + 1}`} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          </Col>
          <Col md="1" sm="12">
            <FormGroup>
              <Label for="size">Font size</Label>
              <Input
                autoComplete="off"
                type="text"
                id="size"
                value={fontSize}
                onChange={(e) => {
                  setFontSize(e.target.value);
                }}
              />
            </FormGroup>
          </Col>

          <Col md="2" sm="12">
            <FormGroup>
              <Label for="end">Check date</Label>
              <Flatpickr
                id="end"
                className="form-control"
                data-enable-time
                options={{
                  dateFormat: 'Z',
                  altInput: true,
                  altFormat: 'm-d-Y H:i',
                }}
                value={checkDate}
                onChange={(val) => {
                  setCheckDate(new Date(val).getTime());
                }}
              />
            </FormGroup>
          </Col>
          <Col md="2" sm="12">
            <FormGroup>
              <Label for="download">{}</Label>
              <Button
                disabled={!startDate || !endDate || !checkDate}
                size="sm"
                color="success"
                className="form-control"
                type="button"
                onClick={() => {
                  const query = `driver_id=${driverId}&start=${startDate}&end=${endDate}&check_date=${checkDate}&font_size=${fontSize}&company_id=${company}`;
                  accountingService.getStatement2(query).then((res) => {
                    setPdfData([res]);
                    getFile(res.fileName);
                  });
                }}
              >
                Apply
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <ModalBody
        style={{
          minHeight: '80vh',
          height: '80vh',
        }}
      >
        <div style={{ height: '100%' }}>
          <iframe
            src={file}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        {item ? <h4>Received: {item.email}</h4> : null}
        <IconButton
          disabled={subDisable}
          onClick={() => {
            sub();
          }}
          color="primary"
          component="label"
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        {data ? (
          <h3> {'(' + (itemIndex + 1) + '/' + data.length + ')'}</h3>
        ) : null}
        <IconButton
          disabled={addDisable}
          onClick={() => {
            add();
          }}
          color="primary"
          component="label"
        >
          <ArrowForwardIosIcon />
        </IconButton>

        <Button
          size="sm"
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => {
            handleSendAlert();
          }}
        >
          <Icon.Send size={22} /> Send
        </Button>
        <Button
          size="sm"
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => {
            toggle();
          }}
        >
          <Icon.Power size={22} /> Cancel
        </Button>
      </ModalFooter>
    </div>
  );
}
